<template>
    <div class="upload-box">
        <div class="header flex-align-between">
            <span>批量测评</span>
            <div class="back flex-align" @click="goBack">
                <img src="@/assets/newReport/back.png" alt="">
                返回
            </div>
        </div>
        <div class="content-box">
            <div class="title flex-align">
                <span class="line"></span>
                上传作品
            </div>
            <div class="content flex-align-between">
                <div class="content-left">
                    <el-form :model="fromData" :rules="rules" ref="ruleForm" label-width="80px" label-position="left">
                        <el-form-item label="年级" prop="grade" v-if="role == 11">
                            <el-select v-model="fromData.grade" placeholder="请选择年级" @change="gradeChange"
                                :disabled="disabled">
                                <el-option :label="item.name" :value="item.id" v-for="(item, index) in gradeList"
                                    :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="班级" prop="class" v-if="role == 11">
                            <el-select v-model="fromData.class" placeholder="请选择班级" @change="classChange2"
                                :disabled="disabled">
                                <el-option :label="item.name" :value="item.id" v-for="(item, index) in classList"
                                    :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="班级" prop="class" v-else>
                            <el-select v-model="fromData.class" placeholder="请选择班级" @change="classChange"
                                :disabled="disabled">
                                <el-option :label="item.name" :value="item.id" v-for="(item, index) in classList"
                                    :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="学期" prop="orgId">
                            <el-select v-model="fromData.orgId" placeholder="请选择学期" :disabled="disabled">
                                <el-option :label="item.termName" :value="item.key" v-for="(item, index) in termList"
                                    :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="类型" prop="type">
                            <el-select v-model="fromData.type" placeholder="请选择试卷类型" @change="typeChange"
                                :disabled="disabled">
                                <el-option :label="item.label" :value="item.value" v-for="(item, index) in typeList"
                                    :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="试卷名称" prop="testId">
                            <el-select v-model="fromData.testId" placeholder="请选择试卷名称" :disabled="disabled">
                                <el-option :label="item.name" :value="item.id" v-for="(item, index) in testList"
                                    :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="content-right">
                    <div class="file-box flex-column-center">
                        <el-upload class="upload-demo" drag action="" multiple :show-file-list="false"
                            :http-request="httpRequest">
                            <div>
                                <i class="custom-upload-icon">
                                </i>
                                <div class="el-upload__text">将文件拖到此处，<br />
                                    或<em>点击上传</em></div>
                            </div>
                        </el-upload>
                    </div>
                    <div class="tips-box flex-column">
                        <span>注意：</span>
                        <span>1.请确保作品的二维码，条码信息清晰无遮挡；且作品四周完整无折叠，破损。</span>
                        <span>2.图片格式支持.png、.jpg等</span>
                        <span>3.有多页的作品，需完整拍摄逐页上传</span>
                        <span>4.作品上传后系统会对作品进行识别、校验，时间大概需要10分钟，识别过程中请勿关闭页面</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="list-box">
            <div class="title flex-align">
                <span class="line"></span>
                作品列表
                <div class="menu-box flex-align">
                    <el-button type="primary" plain @click="createReport">生成报告</el-button>
                    <div class="mennu-item flex-align" @click="delAll">
                        <img src="@/assets/upload/del.png" alt="">
                        删除
                    </div>
                    <div class="mennu-item flex-align" @click="exportWork">
                        <img src="@/assets/upload/export.png" alt="">
                        导出作品列表
                    </div>
                </div>
            </div>
            <div class="status-box">
                <div class="success flex-align">
                    <span><span class="text">成功试卷：</span>上传并识别成功 <span class="num">{{ successful }}</span> 人</span>
                </div>
                <div class="fail flex-align">
                    <span class="text">问题试卷：</span>
                    <div class="fail-box flex-align">
                        <!-- <div class="fail-item flex-align-center">上传失败：2页</div>
                        <div class="fail-item flex-align-center">试卷不全：8人</div> -->
                        <div class="fail-item flex-align-center" :class="{ 'fail-item-active': failIndex == -1 }"
                            @click="checkErr('', -1)">全部</div>
                        <div class="fail-item flex-align-center" :class="{ 'fail-item-active': failIndex == 0 }"
                            @click="checkErr(99, 0)">识别失败：{{ resultData.recognizeFail }}人</div>
                        <div class="fail-item flex-align-center" :class="{ 'fail-item-active': failIndex == 1 }"
                            @click="checkErr(3, 1)">试卷不全{{ resultData.incompletePaper }}人</div>
                        <div class="fail-item flex-align-center" :class="{ 'fail-item-active': failIndex == 2 }"
                            @click="checkErr(1, 2)">试卷重复：{{ resultData.repeat }}人</div>
                        <div class="fail-item flex-align-center" :class="{ 'fail-item-active': failIndex == 3 }"
                            @click="checkErr(2, 3)">非本次测评卷：{{ resultData.notThisExam }}页</div>
                    </div>
                </div>
            </div>
            <el-table :data="tableData" style="width: 100%;" @selection-change="handleSelectionChange"
                :header-cell-style="{ background: '#E5F7F3', color: '#333333' }">
                <el-table-column type="selection" align="center" width="80">
                </el-table-column>
                <el-table-column prop="fileName" label="作品名称" width="180" align="center"></el-table-column>
                <el-table-column label="上传结果" align="center" width="180">
                    <template slot-scope="scope">
                        <div class="successRes flex-align-center">
                            <img src="@/assets/upload/success.png" alt="">
                            <span>上传成功</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="识别结果" align="center" width="200">
                    <template slot-scope="scope">
                        <div class="successRes flex-align-center">
                            <img src="@/assets/upload/uploading.png" alt="" v-if="scope.row.status == 0">
                            <img src="@/assets/upload/success.png" alt="" v-if="scope.row.status == 1">
                            <img src="@/assets/upload/error.png" alt="" v-if="scope.row.status > 1">
                            <div class="res-bos flex-align">
                                <span>{{ statusList[scope.row.status].text }}</span>
                                <span class="err" v-if="scope.row.status > 1">({{ statusList[scope.row.status].reason
                                }})</span>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="bindUserName" label="学生姓名" align="center" width="180"></el-table-column>
                <el-table-column label="校验结果" align="center" width="180">
                    <template slot-scope="scope">
                        {{ checkList[scope.row.checkState] }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" @click="delWork(scope.row.id)">删除</el-button>
                        <el-button type="text" @click="viewImg(scope.row.filePath)">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pages flex-align-around">
                <el-pagination background layout="prev, pager, next,total,jumper" :page-size='limit' :total="total"
                    @current-change="pageChange" @prev-click="prev" @next-click="next" :current-page="page">
                </el-pagination>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" width="20%" :before-close="handleClose" center :lock-scroll="false">
            <span class="tips flex-align-center">确定删除作品？</span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="confirm">确 定</el-button>
                <el-button @click="dialogVisible = false">取 消</el-button>
            </span>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisible2" width="20%" :before-close="handleClose" center :lock-scroll="false">
            <span class="tips flex-align-center">本班级已经上传过本套试卷的作品</span>
            <span class="tips flex-align-center">是否<strong>重新上传</strong>并<strong>覆盖</strong>上次作品</span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="uploadAgain = true, dialogVisible2 = false">重新上传</el-button>
                <el-button @click="dialogVisible2 = false">取 消</el-button>
            </span>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisible3" center :lock-scroll="false">
            <div class="img-box">
                <img :src="imgUrl" alt="">
            </div>
        </el-dialog>
    </div>
</template>
<script>
const OSS = require('ali-oss');
export default {
    data() {
        return {
            date:new Date().getTime(),
            role: sessionStorage.getItem('role'),
            fromData: {
                class: '',
                orgId: '',
                type: '',
                testId: '',
                grade: ''
            },
            rules: {
                grade: [{ required: true, message: '请选择年级', trigger: 'change' }],
                class: [{ required: true, message: '请选择班级', trigger: 'change' }],
                orgId: [{ required: true, message: '请选择活学期', trigger: 'change' }],
                type: [{ required: true, message: '请选择类型', trigger: 'change' }],
                testId: [{ required: true, message: '请选择试卷名称', trigger: 'change' }],
            },
            tableData: [],
            resultData: {
                recognizeFail: 0,
                repeat: 0,
                notThisExam: 0,
                incompletePaper: 0
            },
            successful: 0,
            dialogVisible: false,
            dialogVisible2: false,
            dialogVisible3: false,
            classList: [],
            termList: [],
            typeList: [
                { label: '前测试卷', value: 1 },
                { label: '后测试卷', value: 2 },
                { label: '月测试卷', value: 4 },
                { label: '周测试卷', value: 5 },
            ],
            termId: '',
            testList: [],
            client: null,
            recordId: null,
            limit: 10,
            page: 1,
            total: 10,
            statusList: {
                0: { text: '正在识别...', reason: "" },
                1: { text: '识别成功', reason: "" },
                2: { text: '识别失败', reason: "oss文件下载错误" },
                3: { text: '识别失败', reason: "二维码未找到" },
                4: { text: '识别失败', reason: "识别发生异常" },
                5: { text: '识别失败', reason: "没有匹配到字" },
                6: { text: '识别失败', reason: "空白的字格" },
                7: { text: '识别失败', reason: "字形模板文件不存在" },
                8: { text: '识别失败', reason: "作品图不完整或不清晰" },
            },
            checkList: {
                0: '/',
                1: '重复',
                2: '非本次测评卷',
                3: '试卷不全',
                4: '非本班学生',
                5: '条形码未找到',
                6: '非本校学生'
            },
            imgUrl: '',
            delId: null,
            multipleSelection: [],
            disabled: false,
            intervalId: null,
            isEnd: true,
            uploadAgain: false,
            checkState: '',
            failIndex: -1,
            gradeList: [],
            schoolId: ''
        }
    },
    computed: {
        checkFromData() {
            const { class: classValue, orgId, type, testId } = this.fromData;
            return classValue && orgId && type && testId;
        },
    },
    watch: {
        checkFromData(newVal) {
            console.log('监听值:', newVal)
            if (newVal) {
                this.saveRecord()
            }
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        handleClose() {

        },
        async getGradeIdListBySchoolId() {
            let data = {
                schoolId: sessionStorage.getItem('schoolId')
            }
            let resData = await this.$Api.Upload.getGradeIdListBySchoolId(data);
            console.log('年级列表:', resData);
            this.gradeList = resData.data;
        },
        async getClassList() {
            let data = {
                uId: await this.getUserInfo()
            }
            let resData = await this.$Api.Upload.getClassList(data);
            // console.log('班级列表:', resData);
            this.classList = resData.data;

            if (this.$route.query.id) {
                if (this.$route.query.grade) {
                    this.fromData.grade = Number(this.$route.query.grade);
                    this.termId = Number(this.$route.query.grade);
                } else {
                    this.termId = this.classList.find(item => item.id == Number(this.$route.query.classId)).termId;
                }
                this.getTestList()
            }
        },
        async getClassList2() {
            let data = {
                schoolId: sessionStorage.getItem('schoolId'),
                termId: this.fromData.grade
            }
            let resData = await this.$Api.Upload.getClassListBySchoolId(data);
            console.log('班级列表:', resData);
            this.classList = resData.data;
            this.classList.unshift({
                id: -1,
                name: '全部'
            })
            if (this.$route.query.id) {
                if (this.$route.query.grade) {
                    this.fromData.grade = Number(this.$route.query.grade);
                    this.termId = Number(this.$route.query.grade);
                } else {
                    this.termId = this.classList.find(item => item.id == Number(this.$route.query.classId)).termId;
                }
                this.getTestList()
            }
        },
        classChange(val) {
            // console.log('班级', val);
            this.fromData.testId = '';
            this.termId = this.classList.find(item => item.id == val).termId;
            if (this.fromData.type) {
                this.getTestList()
            }
        },
        classChange2(val) {
            // console.log('班级2', val);
            this.fromData.testId = '';
            this.getTestList()
            // if (this.fromData.type) {
            //     this.getTestList()
            // }
        },
        gradeChange(val) {
            console.log('选择年级:', val);
            this.fromData.class = '';
            this.testList = [];
            if (this.role == 11) {
                this.fromData.testId = '';
                this.termId = val;
                this.getClassList2()
            } else {
                this.fromData.testId = '';
                this.termId = val;
                this.getTestList();
            }

        },
        async getUserInfo() {
            let resData = await this.$Api.Home.getUserInfo();
            this.schoolId = resData.data.schoolId;
            return resData.data.id
        },
        async queryTermList() {
            let resData = await this.$Api.DataScreen.queryTermList();
            this.termList = resData.data.slice(0,1)
        },
        typeChange() {
            this.fromData.testId = '';
            this.getTestList()
            // if (this.fromData.class) {
            //     this.getTestList()
            // }
        },
        async getTestList() {
            this.testList = [];
            if (this.termId && this.fromData.type) {
                let data = {
                    grade: this.termId,
                    type: this.fromData.type
                };
                let resData = await this.$Api.Upload.getTestList(data);
                console.log('试卷名称:', resData);
                this.testList = resData.data
            } else {
                console.log('查询试卷名称缺少条件')
            }

        },
        async initOss() {
            let resData = await this.$Api.Upload.getQueryToken();
            // console.log('获取ossTpken:', resData);
            this.client = new OSS({
                region: 'oss-cn-beijing',
                authorizationV4: true,
                accessKeyId: resData.data.accessKeyId, // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                accessKeySecret: resData.data.accessKeySecret,
                stsToken: resData.data.stsToken,// 从STS服务获取的安全令牌（SecurityToken）。
                bucket: resData.data.bucket,// 填写Bucket名称，例如examplebucket。
            })
        },
        httpRequest(data) {
            if (this.$route.query.status == 1) {
                this.$message.error('报告生成中，不允许删除或新增作品！');
                this.dialogVisible = false;
                return
            }
            let hasEmptyValue;
            if (this.role == 11) {
                hasEmptyValue = Object.values(this.fromData).some(value => value === '');
            } else {
                // hasEmptyValue = Object.values(this.fromData).some(value => value === '');
                hasEmptyValue = Object.entries(this.fromData)
                    .filter(([key]) => key !== 'grade') // 排除 grade
                    .some(([, value]) => value === '');
            }

            console.log('hasEmptyValue:', hasEmptyValue);
            if (hasEmptyValue) {
                this.$message.error('请选择完正确选项后再上传');
                return;
            };
            console.log('自定义上传方法:', data);
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth() + 1;
            const day = currentDate.getDate();
            const timestamp = currentDate.getTime() +'_'+ Math.floor(Math.random() * (10 - 1)) + 0;
            let name = `static/import/upload/${year}/${month}/${day}/teahcer-identify-upload-${timestamp}.${data.file.type.split("/")[1]}`
            this.client.put(name, data.file)
                .then(res => {
                    console.log('上传成功:', res);
                    this.saveImg(data.file.name, name);
                })
                .catch(err => {
                    console.log('上传失败:', err)
                })
        },
        // 获取记录id
        async saveRecord() {
            if (this.$route.query.id) {
                return
            }
            let recordName;
            if (this.role == 11) {
                if(this.fromData.class>-1){
                    recordName = this.classList.find(item => item.id == this.fromData.class).name + ' ' + this.testList.find(item => item.id == this.fromData.testId).name;
                }else{
                    recordName = this.gradeList.find(item => item.id == this.fromData.grade).name + ' ' + this.testList.find(item => item.id == this.fromData.testId).name;
                }
            } else {
                recordName = this.classList.find(item => item.id == this.fromData.class).name + ' ' + this.testList.find(item => item.id == this.fromData.testId).name;
            }
            let data = {
                recordName,
                examType: this.fromData.type,
                examId: this.fromData.testId,
                classId: this.fromData.class,
                termKey: this.fromData.orgId,
                grade: this.role == 11 ? this.fromData.grade : '',
                schoolId: sessionStorage.getItem('schoolId')
            }
            let resData = await this.$Api.Upload.saveRecord(data);
            console.log('获取记录id:', resData);
            if (resData.code == 200) {
                this.recordId = resData.data;
            } else if (resData.code == 301) {
                this.dialogVisible2 = true;
                this.recordId = resData.data;
            }
        },
        // 保存作品图片
        async saveImg(fileName, filePath) {
            let data = {
                id: this.recordId,
                img: {
                    fileName,
                    filePath
                }
            }
            let resData = await this.$Api.Upload.saveImg(data);
            console.log('保存作品图片:', resData);
            this.getWorkPage()
        },
        // 更新作品列表
        async getWorkPage(lessonId) {
            let data = {
                lessonId: lessonId ? lessonId : this.recordId,
                current: this.page,
                size: this.limit,
                checkState: this.checkState,
            }
            let resData = await this.$Api.Upload.getWorkPage(data);
            console.log('作品列表:', resData);
            this.tableData = resData.data.page.records;
            this.resultData = resData.data.problem;
            this.successful = resData.data.successful;
            this.total = resData.data.page.total;
            this.isEnd = this.tableData.every(item => item.status !== 0);
            if (this.isEnd) {
                clearInterval(this.intervalId)
            } else {
                if (this.intervalId) {
                    console.log('当前有定时器正在执行')
                } else {
                    this.getWorkPageVal(lessonId)
                }
            }
        },
        pageChange(page) {
            console.log('当前页:', page);
            this.page = page;
            this.getWorkPage()
        },
        prev() { },
        next() { },
        delWork(id) {
            this.delId = id;
            this.dialogVisible = true;
        },
        delAll() {
            if (this.multipleSelection.length == 0) {
                this.$message.error('请选择要删除的作品');
                return
            }
            this.dialogVisible = true;
        },
        async confirm() {
            if (this.$route.query.status == 1) {
                this.$message.error('报告生成中，不允许删除或新增作品！');
                this.dialogVisible = false;
                return
            }
            let ids = [];
            if (this.multipleSelection.length > 0) {
                this.multipleSelection.map(item => {
                    ids.push(item.id)
                })
            } else {
                ids.push(this.delId);
            }
            let resData = await this.$Api.Upload.delWork(JSON.stringify(ids));
            console.log('删除成功:', resData);
            if (resData.code == 200) {
                this.$message({
                    message: '删除成功',
                    type: 'success'
                });
                this.getWorkPage();
            } else {
                this.$message.error('删除失败，请联系管理员');
            }
            this.dialogVisible = false;
        },
        async viewImg(path) {
            let data = {
                path
            }
            let resData = await this.$Api.Upload.getTempUrl(data);
            console.log('临时访问链接:', resData);
            this.imgUrl = resData.data;
            this.dialogVisible3 = true;
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        exportWork() {
            let data = {
                id: this.recordId
            }
            this.$Api.Upload.downloadWordList(data)
                .then(res => {
                    const blob = new Blob([res], {
                        type: 'application/octet-stream'
                    });
                    const url = window.URL.createObjectURL(blob);
                    // 以动态创建a标签进行下载
                    const a = document.createElement('a');
                    const fileName = '作品列表';
                    a.href = url;
                    a.download = fileName + '.xlsx';
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(err => {
                    console.log(err)
                })
        },
        // 循环获取作品列表
        getWorkPageVal(lessonId) {
            this.intervalId = setInterval(async () => {
                let data = {
                    lessonId: lessonId ? lessonId : this.recordId,
                    current: this.page,
                    size: this.limit,
                }
                let resData = await this.$Api.Upload.getWorkPage(data);
                console.log('作品列表:', resData);
                this.tableData = resData.data.page.records;
                this.resultData = resData.data.problem;
                this.successful = resData.data.successful;
                this.total = resData.data.page.total;
                this.isEnd = this.tableData.every(item => item.status !== 0);
                if (this.isEnd) {
                    clearInterval(this.intervalId)
                }
            }, 5000);
        },
        // 查询问题试卷
        checkErr(checkState, index) {
            this.page = 1;
            this.checkState = checkState;
            this.failIndex = index;
            this.getWorkPage()
        },
        async createReport() {
            // if (this.tableData.length == 0) {
            //     this.$message.error('请先上传作品');
            //     return
            // }
            let resData = await this.$Api.Upload.createReport(this.recordId);
            console.log('生成报告:', resData);
            if (resData.data.data.code == 301) {
                this.$alert(resData.data.data.msg, '提示', {
                    dangerouslyUseHTMLString: true,
                    center: true,
                    showConfirmButton: false,
                    type: 'warning'
                });
            } else {
                this.$alert(`共上传识别${this.total}份作品，报告开始生成中`, '提示', {
                    center: true,
                    type: 'success',
                    confirmButtonText: '返回上传列表，继续上传作品',
                    callback: action => {
                        this.$router.go(-1);
                    },
                    closeOnClickModal: false,
                    confirmButtonClass: 'confirmalert' // 添加这行来设置确定按钮的背景色
                });
            }
        },
    },
    mounted() {
        this.getGradeIdListBySchoolId();

        if (this.role == 11) {
            // this.getClassList2();
        } else {
            this.getClassList();
        }
        this.queryTermList();
        this.initOss();
        if (this.$route.query.id) {
            this.disabled = true;
            this.recordId = this.$route.query.id;
            this.getWorkPage(this.$route.query.id);
            this.fromData.class = Number(this.$route.query.classId);
            this.fromData.orgId = Number(this.$route.query.termYear);
            this.fromData.type = Number(this.$route.query.examType);
            this.fromData.testId = Number(this.$route.query.examId);
            this.termId = Number(this.$route.query.grade)
            this.getTestList();
            this.getClassList2();
        }
    },
    beforeDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }
}
</script>
<style lang="less" scoped>
@import url('./less/upload-list.less');
</style>
<style lang="less">
.confirmalert {
    background-color: #21BD99 !important;
    border-color: #21BD99;
}
</style>